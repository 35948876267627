<template>
  <div class="deposit-log-view">
     <div v-if="!$store.state.isMobileLayout" class="types">
       <div class="type-title">交易类型</div>
       <div
         v-for="(type, idx) in typeList"
         :key="idx"
         :class="[
           'type-btn btn shadow',
           { active: operation_type === type.operation_type },
         ]"
         @click="changeType(type.operation_type)"
         v-html="type.text"
       ></div>
     </div>
     <div
       :class="[$store.state.isMobileLayout ? 'full-content' : 'right-content']"
     >
       <div class="top flex-ac flex-jb">
         <month-picker @setDate="setDate"></month-picker>
         <div v-if="$store.state.isMobileLayout" class="control relative">
           <div
             class="btn type-btn active"
             @click="is_show_option = !is_show_option"
           >
             交易类型
           </div>
           <transition name="fade-up">
             <div v-if="is_show_option" class="option-modal">
               <div class="label">请&nbsp;选&nbsp;择</div>
               <div
                 v-for="(type, idx) in typeList"
                 :key="idx"
                 :class="[
                   'option',
                   { active: operation_type === type.operation_type },
                 ]"
                 :value="type.operation_type"
                 @click="changeType(type.operation_type)"
                 v-html="type.text"
               ></div>
             </div>
           </transition>
         </div>
       </div>

    <div class="top flex-ac flex-jb">
        <div class="hint">
          最新五笔
        </div>
    </div>
    <div class="content">
      <a-spin :spinning="loading">
        <div v-if="list.length > 0" class="table">
          <div
            v-for="item in list"
            :key="idx"
            class="table-row flex-ac flex-jb log-item"
          >
            <p class="title">单号： {{ item.billno }}</p>
            <div class="detail">
              <div>
                <p>平台类型&nbsp;({{ item.platformname }})</p>
                <p>訂單狀態：&nbsp;{{ item.remark === "X" ? '未結算' : '已結算' }} </p>
                <p>输赢金额：&nbsp;{{ item.netAmount }}</p>
                <p>下注时间：&nbsp;{{ item.betTime }}</p>
              </div>
              <div>
                <p v-text="item.record_date"></p>
                <p>&plus;{{ item.score_cash }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">暫無數據</div>
      </a-spin>
      <div class="bottom">
        <div class="hint">
          温馨提示：<br />
          记录有时可能存在延遲，感謝您的理解，如有疑
          问請聯繫客服，我们将幾時为您核實处理。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonthPicker from '@/components/month-picker.vue'
import { post } from '@/axios'
import pagination from '@/mixins/pagination'

export default {
  name: 'bets_log',
  components: {
    //MonthPicker
  },
  mixins: [pagination],
  data() {
   return {
       loading: true,
       operation_type: '',
       is_show_option: false,
       typeList: [
         {
           text: '全部',
           operation_type: ''
         },
         {
           text: '結算',
           operation_type: '0'
         },
         {
           text: '未結算',
           operation_type: '1'
         }
       ],
       month_list: [],
       list: [],
       show_pages: false
     }
  },
  async created() {
    await this.getData()
  },
  methods: {
    changeType(val) {
      this.operation_type = val
      this.is_show_option = false
      this.pages = 1
    },
    async getData() {
      const mapStr = JSON.stringify({
        monthDate: this.monthDate,
        operation_type: this.operation_type,
        pages: this.pages,
        rows: this.rows
      })

      try {
        this.loading = true
        const { betsList } = await post(
          'record_betsRecord.do',
          this.Qs.stringify({ mapStr })
        )
        this.list = betsList
      } catch {
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    pages() {
      this.getData()
    },
    monthDate() {
      this.getData()
    },
    operation_type() {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.deposit-log-view {
  padding: 2rem 2%;
}

.content {
  width: 100%;
}

.table {
  width: 100%;
  margin: 0.5rem 0;

  .table-row {
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 1px;
    padding: 1rem 2rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      margin-bottom: 0.5rem;
    }

    .detail {
      width: 100%;
      display: flex;
      justify-content: space-between;
      line-height: 1.2;

      > div:last-child {
        text-align: right;
      }
    }
  }
}

.bottom {
  width: 90%;
  height: 6rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  padding-bottom: 1rem;
}

.hint {
  width: 50%;
  line-height: 1.5;
  font-size: 0.8rem;
  color: #999;
  text-align: left;
}

.pages {
  position: relative;
  font-size: 0.9rem;
  margin-top: 1rem;
  user-select: none;
}

.page-btns {
  width: 100%;
}

.page-btn {
  color: white;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #c9a67c;
  }
}

.page-index {
  width: 20%;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
}

.right-content {
  flex: 1;
  margin-left: 0.5rem;
}

.type-title {
  color: white;
  margin: 0.5rem 0 1rem;
  font-size: 1rem;
}

.type-btn {
  color: white;
  margin-bottom: 0.8rem;
  cursor: pointer;
  letter-spacing: 10px;
}

@include md {
  .select {
    font-size: 0.8rem;
  }

  .full-content {
    width: 100%;
  }

  .option-modal {
    border-radius: 5px;
    z-index: 1;

    .label {
      padding: 0;
      font-size: 0.7rem;
    }

    .option {
      font-size: 0.8rem;
    }
  }

  .table-row {
    color: #999;
    padding: 0.5rem;

    &:hover {
      color: white;
    }
  }

  .row-record {
    font-size: 0.7rem;
  }

  .bottom {
    width: 100%;
    position: relative;
    margin-top: 1rem;

    .hint {
      width: 100%;
    }
  }

  .pages {
    font-size: 0.8rem;
    display: block;
  }

  .page-index {
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
  }
}
</style>
